  if (module.hot) {
    module.hot.accept();
  }

  import axios from "axios";
  const $axios = axios;

$(document).ready(function () {

  const WOW = require('wowjs');
  window.wow = new WOW.WOW();
  window.wow.init();

  const progressBar = document.querySelector(".progress-bar");
  const page = document.querySelector(".page");
  const scrollbarInner = document.querySelector(".progress-bar__inner");
  const header = document.querySelector(".header");
  const socialNetworks = document.querySelector(".social-networks");
  const forms = document.querySelectorAll(".form");
  const input = document.querySelectorAll(".input");
  const headerSticky = document.querySelector(".header-sticky");
  const textBlock = document.querySelector(
    ".content-block-about__description .text"
  );
  const btnTop = document.querySelector(".scroll-top");
  const totalHeight = document.body.clientHeight - window.innerHeight;
  scrollbarInner.style.width = 0;

  const hiddenText = () => {
    const quantitySymbols = textBlock.innerHTML.length;
    const buttonMore = document.querySelector(
      ".content-block-about__description .button-read-more"
    );

    if (quantitySymbols >= 800) {
      textBlock.parentElement.classList.add("hidden");
      buttonMore.addEventListener("click", () => {
        textBlock.parentElement.classList.remove("hidden");
      });
    } else {
      textBlock.parentElement.classList.remove("hidden");
    }
  };

  hiddenText();

  input.forEach((item) => {
    item.addEventListener("focus", () => {
      item.parentElement.classList.add("focus");
      if (item.parentElement.classList.contains('mistake')) {
        item.parentElement.classList.remove('mistake');
        item.nextElementSibling.style.display = "none";
      }
    });
  });
  
  input.forEach((item) => {
    item.addEventListener("blur", () => {
      item.parentElement.classList.remove("focus");
    });
  });

  function validateEmail(value) {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(value).toLowerCase());
  }

  forms.forEach((form) => {
    form.addEventListener("submit", submitForm);
  });

  function validateForm(formEl) {
    const errorsUI = (selector, isValid) => {
      let field = selector;
      let display = isValid ? "none" : "block";
      let toggleMethod = isValid ? "remove" : "add";
      let errorBlock = field.parentElement.querySelector(".input-wrap__mistake");
      field.parentElement.classList[toggleMethod]("mistake");
      errorBlock ? (errorBlock.style.display = display) : false;
    };

    const name = formEl.querySelector('[name="name"]');
    const email = formEl.querySelector('[name="email"]');
    const emailIsValid =
      email.value.length > 3 ? validateEmail(email.value) : false;

    errorsUI(name, name.value);
    errorsUI(email, emailIsValid);

    return name.value && emailIsValid;
  }

  function submitForm(e) {
    e.preventDefault();
    const form = e.target;
    const formCaption = document.querySelector('.form-block__caption-wrap');
    const formSuccessCaption = document.querySelector('.form-block__success');
    if (validateForm(form)) {
      const url = "./contact.php";
      const formData = new FormData(form);
      $axios
        .post(url, formData)
        .then((response) => {
          if (response.data && response.data.text === "success") {
            formCaption.classList.add('d-none');
            formSuccessCaption.classList.remove('d-none');
            input.forEach(item => {
              item.value = "";
            })
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    }
  }

  window.addEventListener("scroll", function () {
    if (this.pageYOffset >= header.clientHeight && window.innerWidth < 768) {
      progressBar.classList.add("fixed");
    } else {
      progressBar.classList.remove("fixed");
    }

    if (
      window.pageYOffset >=
        socialNetworks.offsetTop + socialNetworks.clientHeight * 2 &&
      window.innerWidth < 768
    ) {
      socialNetworks.classList.add("hidden");
      headerSticky.classList.add("fixed");
    } else {
      socialNetworks.classList.remove("hidden");
      headerSticky.classList.remove("fixed");
    }

    if (window.pageYOffset > page.clientHeight / 2 - window.innerHeight / 2) {
      btnTop.classList.add("active");
      btnTop.addEventListener("click", () => {
        window.window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      });
    } else {
      btnTop.classList.remove("active");
    }

    scrollbarInner.style.width = (window.scrollY * 100) / totalHeight + "%";
  });
});
